<div class="wrapper">
    <div class="block-center mt-4 wd-xl">
        <!-- START card-->
        <div class="card card-flat">
            <div class="card-header text-center bg-secondary">
                <a href="#">
                    <img class="block-center rounded" src="assets/img/logo-big.png" alt="Image" />
                </a>
            </div>
            <div class="card-body">
                <p class="text-center py-2">{{'recover.title' | translate}}</p>
                <form (ngSubmit)="onSubmit()" #formRecover="ngForm" class="form-validate" role="form" name="formRecover" novalidate="">
                    <p class="text-center">{{'recover.info' | translate}}</p>
                    <div class="form-group">
                      <label class="text-muted">{{'base.mail_address' | translate}}</label>
                      <div class="input-group with-focus">
                          <input class="form-control border-right-0" id="email" class="form-control" ngModel name="email" required email #email="ngModel"/>
                          <div class="input-group-append">
                              <span class="input-group-text text-muted bg-transparent border-left-0">
                                  <em class="fa fa-envelope"></em>
                              </span>
                          </div>
                      </div>
                      <div class="text-danger" *ngIf="errorMessage">{{errorMessage}}</div>
                    </div>
                    <button [disabled]="!formRecover.valid" class="btn btn-primary btn-block" type="submit">{{'recover.reset' | translate}}</button>
                </form>
            </div>
        </div>
        <!-- END card-->
        <div class="p-3 text-center">
            <span>&copy;</span>
            <span>{{ settings.getAppSetting('year') }}</span>
            <span class="mx-2">-</span>
            <span>{{ settings.getAppSetting('name') }}</span>
            <br/>
            <span>{{ settings.getAppSetting('description') }}</span>
        </div>
    </div>
</div>