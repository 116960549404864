<div class="wrapper text-center" style="background-color: #BC4526;">
    <!-- Main section-->
    <section class="_section-container">
        <!-- Page content-->
        <div class="content-wrapper">
            <router-outlet></router-outlet>
        </div>
    </section>
    <!-- Page footer-->
    <footer class="_footer-container" app-footer></footer>
  </div>