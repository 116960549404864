<div class="table-responsive">
  <table class="table table-striped" [ngClass]="{ 'table-hover': hoverStyle }">
    <ng-content select="[header]" *ngIf="config.rows.length > 0"></ng-content>
    <ng-content select="[body]"></ng-content>
  </table>
  <div *ngIf="config.rows.length == 0" class="w-100 text-center mt-2">
    <h4>{{ 's1.table.noElements' | translate }}</h4>
  </div>
  <div class="w-100 text-center mt-4">
    <s1-pagination #pagination [table]="config" [onlinePagination]="onlinePagination" (pageChanged)="paginationUpdated()" ></s1-pagination>
  </div>
</div>