<div style="padding-top: 12vh;">
    <s1-card>
        <div class="text-center mb-4">
            <div class="text-lg mb-3">{{ 'error.404.title' | translate }}</div>
            <p class="lead m0">{{ 'error.404.description' | translate }}</p>
            <p>{{ 'error.404.message' | translate }}</p>
            <img alt="Soluzione 1" class="img-fluid home-logo" src="../../../../assets/img/sign.png"
                style="height: 8rem; margin-top: 1rem;"/>
        </div>
    </s1-card>
    <ul class="list-inline text-center text-sm mb-4">
        <li class="list-inline-item">
            <a class="text-muted text-white" [routerLink]="''">{{ 'error.back_button' | translate }}</a>
        </li>
    </ul>
</div>
