import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter, } from '@angular/core';

export interface IS1Table {
  data: Array<any>, // Tutte le righe
  totalData: number, // Numero di elementi totale
  rows: Array<any>, // Righe per la pagina attuale
  selectedRow: any,
  pagination: {
    page: { // Stesso oggetto di <pagination>
        page: number,
        itemsPerPage: number
    },
    maxSize: number,
    numPages: number
  }
}

@Component({
  selector: 's1-pagination',
  templateUrl: './s1-pagination.component.html',
  styleUrls: ['./s1-pagination.component.scss']
})
export class S1Pagination implements OnInit {

  @Input() table: IS1Table;
  @Input() onlinePagination: boolean = false;
  @Output() pageChanged = new EventEmitter<any>();

  pageItemsList = [5, 10, 25, 50, 100, 200]
 
  constructor() { }
 
  ngOnInit() { 
    //this.changePage()
  }

  public changePage(page: any = this.table.pagination.page) {

    this.table.pagination.page = page

    if (!this.onlinePagination) {
      this.table.totalData = this.table.data.length // Se sono stati aggiunti/rimossi elementi ricalcolo il totale
      let start = (this.table.pagination.page.page - 1) * this.table.pagination.page.itemsPerPage;
      let end = this.table.pagination.page.itemsPerPage > -1 ? (start + this.table.pagination.page.itemsPerPage) : this.table.totalData;
      this.table.rows = this.table.data.slice(start, end);

      // Quando cancello riga, può darsi che fosse l'unica della pagina, devo quindi caricare quella precedente
      if (this.table.rows.length == 0 && page.page > 1) {
        page.page -= 1
        this.changePage()
      }

    }

    //console.log("UPD dentro pag")
    this.pageChanged.emit(this.table.pagination.page)
  }

  public resetPage() {
    //this.table.pagination.page.page = 1
    this.changePage( { page: 1, itemsPerPage: this.table.pagination.page.itemsPerPage })
  }

  onChangeItemsPerPage(itemsPerPage) {
    this.changePage( { page: 1, itemsPerPage: itemsPerPage })
    //console.log("OGG: ", itemsPerPage)
    //this.pageChanged.emit(this.table.pagination.page)
  }
 
}
