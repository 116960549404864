import { Routes } from '@angular/router';
import { LayoutComponent } from '../layout/layout.component';

import { LockComponent } from './pages/lock/lock.component';
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';
import { Error404Component } from './pages/error404/error404.component';
import { Error500Component } from './pages/error500/error500.component';
import { MainLayoutComponent } from '@app/layout/main-layout/main-layout.component';

export const routes: Routes = [
    {
        path: '',
        component: LayoutComponent,
        children: [
            // Not lazy-loaded routes
            { path: 'lock', component: LockComponent },
            { path: 'maintenance', component: MaintenanceComponent },
            { path: '404', component: Error404Component },
            { path: '500', component: Error500Component },

            { path: '', loadChildren: () => import('./howto/howto.module').then(m => m.HowtoModule) }
        ]
    },
    {
        path: '',
        component: MainLayoutComponent,
        children: [
            { path: '', loadChildren: () => import('./stop/stop.module').then(m => m.StopModule) }
        ]
    },

    // Not found
    { path: '**', redirectTo: '404' },
];
