import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 's1ReadableDate'
})
export class S1ReadableDatePipe extends DatePipe implements PipeTransform  {

  transform(date: Date, ...args: unknown[]): any {

    if (!date) {
      return null;
    }

    if (args.length > 0 && args[0]) { // se passo true come parametro metto anche l'ora
      return super.transform(date, 'dd/MM/yyyy HH:mm:ss');
    }

    return super.transform(date, 'dd/MM/yyyy');

  }

}
