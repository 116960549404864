import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { LoggingService } from '@app/core/services/log.service';
import { S1UIService } from './s1-ui.service';
import { Router } from '@angular/router';
import { TranslatorService } from '@app/core/translator/translator.service';

// Call interfaces
export interface IS1SearchParams {
  sortBy?: string;
  isSortAscending?: boolean;
  page: number;
  pageSize: number;
}

// Response interfaces
export interface IS1PaginationInfo {
  actualPage: number;
  next: boolean;
  pageSize: number;
  previous: boolean;
  totalItems: number;
  totalPages: number;
}

export interface IS1PaginatedResult {
  paginationInfo: IS1PaginationInfo;
}

interface IS1Outcome {
  success: boolean;
  message: string;
  code: number;
  description: string;
}

export interface IS1Response extends HttpResponse<any> {
  outcome: IS1Outcome;
  item?: any;
  results?: any[];
  paginationInfo?: IS1PaginationInfo;
  total?: number;
  zoneId?: string;
  stopName?: string;
  stopsList?: any[];
  zonesList?: any[];
}

@Injectable({
  providedIn: 'root'
})
export class S1HttpClientService {

  constructor(
    private http: HttpClient,
    private logger: LoggingService,
    private ui: S1UIService,
    private router: Router,
    private translator: TranslatorService
  ) { }

  /**
   * Wrapper of `HTTPClient` post function that centralize the managment of base url, errors and UI feedback
   *
   * @param path relative path of the API call.
   * @param body JSON body of the API call.
   * @param showUI determine to show or not UI feedback
   * @returns `Observable` of type `IS1Response`
   */

  post(path: string, body: any, showUI: boolean = true): Observable<IS1Response> {

    this.logger.log('POST: ', path, 200);
    this.logger.log('Par: ', body, 200);

    if (showUI) {
      this.ui.showSpinner();
    }

    return this.http.post<IS1Response>(environment.restBaseUrl + path, body, this.getRequestOptionArgs())
      .pipe(
        map((response: IS1Response) => this.handleResponse(response, showUI)),
        catchError((error: HttpErrorResponse) => this.handleError(error, showUI))
      );
  }

  get(path: string, showUI: boolean = true): Observable<IS1Response> {

    this.logger.log('GET: ', path, 200);

    if (showUI) {
      this.ui.showSpinner();
    }

    return this.http.get<IS1Response>(environment.restBaseUrl + path, this.getRequestOptionArgs())
      .pipe(
        map((response: IS1Response) => this.handleResponse(response, showUI)),
        catchError((error: HttpErrorResponse) => this.handleError(error, showUI))
      );

  }

  getWithCustomApiPath( path: string, showUI: boolean = true): Observable<any> {

    this.logger.log('GET: ', path, 200);

    if (showUI) {
      this.ui.showSpinner();
    }

    return this.http.get<IS1Response>( path, this.getRequestOptionArgs())
      .pipe(
        map((response: IS1Response) => this.handleResponse(response, showUI)),
        catchError((error: HttpErrorResponse) => this.handleError(error, showUI))
      );

  }

  put(path: string, showUI: boolean = true): Observable<IS1Response> {

    this.logger.log('PUT: ', path, 200);

    if (showUI) {
      this.ui.showSpinner();
    }

    return this.http.put<IS1Response>(environment.restBaseUrl + path, this.getRequestOptionArgs())
      .pipe(
        map((response: IS1Response) => this.handleResponse(response, showUI)),
        catchError((error: HttpErrorResponse) => this.handleError(error, showUI))
      );

  }

  delete(path: string, showUI: boolean = true): Observable<IS1Response> {

    this.logger.log('DELETE: ', path, 200);

    if (showUI) {
      this.ui.showSpinner();
    }

    return this.http.delete<any>(environment.restBaseUrl + path, this.getRequestOptionArgs())
      .pipe(
        map((response: IS1Response) => this.handleResponse(response, showUI)),
        catchError((error: HttpErrorResponse) => this.handleError(error, showUI))
      );

  }

  // Download PDF
  getPDF(path) {

    const reqArg = this.getRequestOptionArgsPDF();

    return this.http
      .get<any[]>(path, reqArg)
      .pipe(map(
      (response: any) => {
        return new Blob([response], { type: 'application/pdf' });
      }
    ), catchError((errorResponse: HttpErrorResponse) => {
        this.logger.log('Errore ' , errorResponse.message , 200);
        const res: any = errorResponse;
        if (res.outcome.code === '0005' || res.outcome.code === '0007') {
          // this.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
    ));
  }

  private handleResponse(response: IS1Response, showUI: boolean = true) {

    const s1Response = response;

    this.logger.log('Response: ', s1Response, 200);

    if (s1Response.outcome?.success) {

      if (showUI) {
        this.ui.closeSpinner();
      }

      return s1Response;

    } else {

      throw new HttpErrorResponse({
        status: s1Response.outcome.code,
        statusText: s1Response.outcome.message,
        headers: s1Response.headers,
        url: s1Response.url
      });

    }

  }

  private handleError(error: HttpErrorResponse, showUI: boolean = true) {

    this.logger.log('HTTP Error: ', error, 200);

    if (showUI) {
      this.ui.showHTTPErrorPopup(error);
      this.ui.closeSpinner();
    }

    switch (error.status) {
      case 401: // Unauthorized
      case 403: // Forbidden
        localStorage.clear();
        this.router.navigate(['/login/1']);
        break;
    }

    return throwError(error);

  }

  private getRequestOptionArgs(): any {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept-Language': this.translator.getLanguageInUse() ?? '',
        // 'Authorization': 'Bearer ' + (localStorage.getItem("token") ?? ""),
        'X-Auth-Token': localStorage.getItem('token') != null ? localStorage.getItem('token') : ''
      })
    };

    return httpOptions;
  }

  getRequestOptionArgsPDF(): any {
    this.logger.log('getRequestOptions', '', 200);
    const httpOptions = {
      headers: new HttpHeaders({
        'X-Auth-Token': sessionStorage.getItem('token') != null ? sessionStorage.getItem('token') : ''
      }),
      responseType: 'blob'
    };
    return httpOptions;
  }

}

