<div class="header-bg container">

	<div class="d-flex justify-content-center text-left align-items-center">
		<div class="col-4">
			<img class="" [src]="imgSrcLogo" style="max-width: 100px;" />
		</div>
		<div class="col-3">
			
		</div>
		<a class="col-5 text-right" href="https://www.praticko.it/">
			<p class="mb-0 text-white"><small>{{ 'menu_powered_by' | translate }}</small></p>
			<img class="block-center rounded" src="assets/img/logo_praticko_shuttle.png" alt="praticko logo" style="max-width: 50px;" />
		</a>
	</div>

</div>