<ng-template #modalTemplate>
  <div class="modal-header">
    <h4 class="modal-title">
      {{ title | translate }} 
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body overflow-auto">
    <div class="card-body text-center">
      <ng-content></ng-content>
    </div>
  </div>
</ng-template>