<footer id="footer" class="pt-4 pb-3 text-white">

  <div class="row">
    <div class="container">
      
      <div class="col-xs-6 col-lg-12">
        <img src="assets/img/logo_praticko_shuttle.png" alt="Praticko" class="img-fluid logo-footer" style="max-width: 158px;">
      </div>

      <div class="mt-4 col-xs-12 col-lg-12 d-flex justify-content-center">
        <p class="m-b-0" [innerHTML]="'base.footer_text' | translate"></p>
        <span>&nbsp;-&nbsp;</span>
        <a href="#" title="Chi siamo" class="nav-link text-white p-0">{{ 'menu_privacy_cookie' | translate }}</a>
      </div>

    </div>
  </div>

</footer>

<span class="text-white">&copy; {{settings.getAppSetting('year')}} - {{ settings.getAppSetting('name') }}  - Ver. {{settings.getAppSetting('version')}}</span>