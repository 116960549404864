import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../../../core/settings/settings.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl, AbstractControl } from '@angular/forms';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

    valForm: UntypedFormGroup;
    passwordForm: UntypedFormGroup;

    constructor(public settings: SettingsService, fb: UntypedFormBuilder) {

        let password = new UntypedFormControl('', Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9]{6,10}$')]));
        let certainPassword = new UntypedFormControl('', [Validators.required, this.compareValues(password)]);

        this.passwordForm = fb.group({
            'password': password,
            'confirmPassword': certainPassword
        });

        this.valForm = fb.group({
            'email': [null, Validators.compose([Validators.required, Validators.email])],
            'accountagreed': [null, Validators.required],
            'passwordGroup': this.passwordForm
        });
    }

    submitForm($ev, value: any) {
        $ev.preventDefault();
        for (let c in this.valForm.controls) {
            this.valForm.controls[c].markAsTouched();
        }
        for (let c in this.passwordForm.controls) {
            this.passwordForm.controls[c].markAsTouched();
        }
    }

    ngOnInit() {
    }

    // ---------- PRIVATE METHODS ---------- //

    private compareValues(targetValue: UntypedFormControl) {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (control.value !== targetValue) {
                return { 'valueMismatch': { value: control.value } }; // Error key and value
            }
            return null; // No error if values match
        };
    }

}
