import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ToastrModule } from 'ngx-toastr';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { NgSelectModule } from '@ng-select/ng-select';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown'

import {
    S1Button,
    S1ButtonCheck,
    S1ButtonCheckAll,
    S1Header,
    S1Modal,
    S1ButtonExpand,
    S1InputLabel,
    S1InputDatepicker,
    S1InputText,
    S1InputAutocomplete,
    S1InputSelect,
    S1Table,
    S1Card,
    S1Pagination,
    S1TableGeneralIndexPipe,
    S1ServerDatePipe,
    S1ReadableDatePipe,
    S1StandardSearchModal,
    S1StandardManageSection,
    S1Language,
    S1CurrencyPipe,
    SafePipe
} from './index';

// https://angular.io/styleguide#!#04-10
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        RouterModule,
        BsDatepickerModule,
        PaginationModule,
        ToastrModule,
        TypeaheadModule,
        NgSelectModule
    ],
    declarations: [
        S1Button,
        S1ButtonCheck,
        S1ButtonCheckAll,
        S1Header,
        S1Modal,
        S1ButtonExpand,
        S1InputLabel,
        S1InputDatepicker,
        S1InputText,
        S1InputAutocomplete,
        S1Table,
        S1Card,
        S1Pagination,
        S1TableGeneralIndexPipe,
        S1ServerDatePipe,
        S1ReadableDatePipe,
        S1StandardSearchModal,
        S1StandardManageSection,
        S1Language,
        S1InputSelect,
        S1CurrencyPipe,
        SafePipe
    ],
    providers: [
        S1TableGeneralIndexPipe,
        S1ServerDatePipe,
        S1ReadableDatePipe,
        S1CurrencyPipe,
        SafePipe,
        BsDropdownDirective
    ],
    exports: [
        S1Button,
        S1ButtonCheck,
        S1ButtonCheckAll,
        S1Header,
        S1Modal,
        S1ButtonExpand,
        S1InputLabel,
        S1InputDatepicker,
        S1InputText,
        S1InputAutocomplete,
        S1Table,
        S1Card,
        S1Pagination,
        S1TableGeneralIndexPipe,
        S1ServerDatePipe,
        S1ReadableDatePipe,
        S1StandardSearchModal,
        S1StandardManageSection,
        S1Language,
        S1InputSelect,
        S1CurrencyPipe,
        SafePipe
    ]
})

// https://github.com/ocombe/ng2-translate/issues/209
export class S1Module {
    static forRoot(): ModuleWithProviders<S1Module> {
        return {
            ngModule: S1Module
        };
    }
}
