import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 's1ServerDate'
})
export class S1ServerDatePipe extends DatePipe implements PipeTransform  {

  transform(date: Date, ...args: unknown[]): any {

    if (!date) {
      return null;
    }

    let onlyDayDateString = super.transform(date, 'yyyy-MM-dd');

    onlyDayDateString += 'T00:00:00.000Z';

    return onlyDayDateString; // new Date(onlyDayDateString)

  }

}
