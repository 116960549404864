<div class="content-heading d-flex p-2 mt-1 mx-1">
  <div class="flex-fill">
    <s1-button *ngIf="backPath" [routerLink]="[backPath]" [type]="s1ButtonType.Back" [outline]="true"></s1-button>
    <ng-content select="[left]"></ng-content>
  </div>
  <div class="flex text-center">
    {{ title | translate }}
    <ng-content select="[center]"></ng-content>
  </div>
  <div class="flex-fill text-right">
    <ng-content select="[right]"></ng-content>
  </div>
</div>
