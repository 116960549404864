<ng-template #modalTemplate>
  <div class="modal-header d-flex align-items-center">
    <div class="title">
      <h4 class="modal-title">
        {{ title | translate }} 
      </h4>
      <h5 *ngIf="subtitle">
        {{ subtitle | translate }}
      </h5>
    </div>
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body overflow-auto">
    <div class="card-body" [ngClass]="centered ? 'text-center' : 'text-start'">
      <ng-content></ng-content>
    </div>
  </div>
</ng-template>