import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ICompanyInfo, IRequestBody, IRequestBodyTrips } from '../interfaces/interface';
import { IS1Response, S1HttpClientService } from '@app/s1';
import { environment } from '../../../environments/environment';

const pathCompany = 'company/attributes/web?code=';
const pathCompaniesInfo = 'company/companies/allinfo';
const companyPrefixAttributeKey = 'APP_MC_TC_PREFIX'

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  private request: IRequestBody;
  private requestTrips: IRequestBodyTrips;
  private requestEmail: string;
  private companyList: ICompanyInfo[];

  companyLogoPath = new Subject();

  constructor(private s1HttpClient: S1HttpClientService) { }

  public getRequest(): IRequestBody {
    return this.request;
  }

  public setRequest(v: IRequestBody) {
    this.request = v;
  }

  getCompanyData( companyCode: string) {
    return this.s1HttpClient.getWithCustomApiPath( environment.restBaseUrlWA + pathCompany + companyCode , false);
  }

  public getRequestTrips(): IRequestBodyTrips {
    return this.requestTrips;
  }

  public setRequestTrips(v: IRequestBodyTrips) {
    this.requestTrips = v;
  }

  public getRequestEmail(): any {
    return this.requestEmail;
  }

  public setRequestEmail(v: string) {
    this.requestEmail = v;
  }


  dateForServer( dateTime?: string) {
    const date: Date = new Date();
    const day = date.getDate();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);

    const year = date.getFullYear();
    return '' + year + '-' + month + '-' + day;
  }

  async getCompanyLogo( companyCode: string ) {
    await this.manageCompaniesInfo();
    this.companyList.find( item => {
      if ( item.attributes?.length && item.attributes.find(a => a.name === companyPrefixAttributeKey)?.value === companyCode ) {
        return this.companyLogoPath.next( item.company.logoUrl );
      }
    });
  }

  async getCompanyInfo( companyCode: string ) {
    await this.manageCompaniesInfo();
    return this.companyList.find( item => item.attributes.find(a => a.name === companyPrefixAttributeKey).value === companyCode );
  }

  findValueAndSessionStorage(obj, key, defaultKey) {
    const valueFounded = obj.find( item => item.name === key );

    if (valueFounded !== undefined) {
      sessionStorage.setItem(key, valueFounded.value);
    } else {
      sessionStorage.setItem(key, defaultKey);
    }
  }

  // ---------- PRIVATE METHODS ---------- //

  /** Method that manages the download of the company info when not available */
  private async manageCompaniesInfo(): Promise<void> {
    if (!this.companyList) {
      this.companyList = (await this.getAllCompaniesInfo().toPromise()).results;
    }
  }

  /** Method that calls the API to get the info of all available companies (instead of using a local .json config file) */
  private getAllCompaniesInfo(): Observable<IS1Response> {
    return this.s1HttpClient.getWithCustomApiPath(environment.restBaseUrlWA + pathCompaniesInfo, false);
  }

}
