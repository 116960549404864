import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr';

import { S1Module } from '../s1/s1.module';
import { TabsModule } from 'ngx-bootstrap/tabs';

import { NgxKjuaComponent } from 'ngx-kjua';

import { FlotDirective } from './directives/flot/flot.directive';
import { SparklineDirective } from './directives/sparkline/sparkline.directive';
import { ColorsService } from './colors/colors.service';
import { CheckallDirective } from './directives/checkall/checkall.directive';
import { VectormapDirective } from './directives/vectormap/vectormap.directive';
import { NowDirective } from './directives/now/now.directive';
import { ScrollableDirective } from './directives/scrollable/scrollable.directive';
import { JqcloudDirective } from './directives/jqcloud/jqcloud.directive';

// https://angular.io/styleguide#!#04-10
@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		TranslateModule,
		ToastrModule.forRoot(),
		S1Module.forRoot(),
		NgxKjuaComponent,
		TabsModule.forRoot(),
	],
	providers: [
		ColorsService
	],
	declarations: [
		FlotDirective,
		SparklineDirective,
		CheckallDirective,
		VectormapDirective,
		NowDirective,
		ScrollableDirective,
		JqcloudDirective,
	],
	exports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		TranslateModule,
		RouterModule,
		ToastrModule,
		FlotDirective,
		SparklineDirective,
		CheckallDirective,
		VectormapDirective,
		NowDirective,
		ScrollableDirective,
		JqcloudDirective,
		S1Module,
		NgxKjuaComponent,
		TabsModule
	]
})

// https://github.com/ocombe/ng2-translate/issues/209
export class SharedModule {
	static forRoot(): ModuleWithProviders<SharedModule> {
		return {
			ngModule: SharedModule
		};
	}
}
